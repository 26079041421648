<template>
  <div class="exchange-wrap">
    <NavHeader title="邻里值记录"></NavHeader>
    <div class="content">
      <div class="reward-wrapper">
        <div>当前邻里值</div>
        <div class="reward-sum">{{ user_info.reward }}</div>
      </div>
      <div class="rewards">
        <div class="title-wrap">
          邻里值记录
        </div>
        <van-list v-if=" rewards.length " v-model="loading" :finished="finished" finished-text="没有更多了" @load="flipOver" :immediate-check="false">
          <div v-for="(reward,index) in rewards" :key="index">
            <div class="reward-item">
              <div class="name-wrap">
                <div class="name">{{ reward.title }}</div>
                <div class="seller-name" v-if="reward.seller_name">{{ reward.seller_name }}</div>
                <div class="time">{{ reward.date_added }}</div>
              </div>
              <div class="number">{{ reward.points }}</div>
            </div>
          </div>
        </van-list>
        <div v-if="!rewards.length">
          <xy-not-tip message="暂无邻里值记录"></xy-not-tip>
        </div>
      </div>
    </div>
    <Footer></Footer>
  </div>
</template>

<script>
import { mapState } from 'vuex'
import NavHeader from '@/components/nav-header.vue'
import Footer from '@/components/foot2022.vue'
import XyNotTip from '@/components/xy-not-tip.vue'

export default {
  name:"integralHistory",
  data () {
    return {
      rewards: [],
      page: 1,
      per_page: 10,
      loading: false,
      finished: false,
    }
  },
  components: {
    NavHeader,
    Footer,
    XyNotTip,
  },
  computed: {
    ...mapState(['user_info', 'ocApi']),
  },
  created () {
    this.getHistoryList();
    this.$store.dispatch('updateMember')
  },
  methods: {
    getHistoryList: function () {
      let that = this;
      let page = that.page;
      let per_page = that.per_page;
      that.$axios.get(that.ocApi + 'account/me/rewards', {
        params: {
          per_page,
          page
        }
      })
        .then(res => {
          if (res.data.current_page >= res.data.last_page) {
            that.finished = true;
          }
          if (res.data.total == 0) {
            return;
          }
          let orderListData = res.data.data;
          let rewards = that.rewards;
          rewards.push(...orderListData)
          that.rewards = rewards
          that.loading = false

        })
        .catch(error => {
          window.console.log(error)
        });
    },
    flipOver: function () {
      this.page += 1
      this.getHistoryList()
    },
  },
}
</script>

<style lang="scss" scoped>
.content {
  padding-top: 46px;
  background-color: #f7f7f7;
  .reward-wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 25px 0;
    font-size: 14px;
    line-height: 14px;
    color: #333333;
    border-bottom: 10px solid #f6f6f7;
    background-color: #fff;
    .reward-sum {
      background: linear-gradient(90deg, #1bd7ff, #00eb57);
      -webkit-background-clip: text;
      -webkit-text-fill-color: transparent;
      font-size: 34px;
      line-height: 34px;
      font-weight: 600;
      margin-top: 17px;
    }
    .regulation {
      font-size: 14px;
      line-height: 14px;
      color: #333333;
      align-self: flex-end;
      margin-right: 25px;
      margin-top: 13px;
      .iconfont {
        margin-right: 10px;
      }
    }
  }
  .rewards {
    width: 100%;
    box-sizing: border-box;
    padding: 0 20px;
    background-color: #fff;
    .title-wrap {
      padding: 17px 0 10px;
      font-size: 16px;
      color: #333333;
      font-weight: 600;
    }
    .reward-item {
      padding: 20px 0 22px;
      border-bottom: 1px solid #eeeeee;
      display: flex;
      justify-content: space-between;
      align-items: center;
      .name-wrap {
        flex: 1;
        .name {
          font-size: 15px;
          color: #39393b;
          line-height: 15px;
        }
        .seller-name {
          font-size: 12px;
          color: #9fa3a9;
          margin-top: 8px;
          line-height: 12px;
        }
        .time {
          font-size: 12px;
          color: #9fa3a9;
          margin-top: 8px;
          line-height: 12px;
        }
      }
      .number {
        background: linear-gradient(90deg, #1bd7ff, #00eb57);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
        font-size: 15px;
        font-weight: 600;
      }
    }
  }
}
</style>